.App {
  margin: 0;
  padding: 0;
}

.Home {
    background-color: black;
}
.Carousel {
    margin:auto;
}



.Carousel img {
    width: 100%;
    margin: auto;
}

.CarouselDes{
    height: 50px;
    text-align: center;
    font-size: 24px;
    color: #FFFFFF;
    line-height: 50px;

}

.CarouselDot{
    margin-bottom: 70px;
}

.NowPlaying {
    background-color: #121212;
    color: #f5c518;
    font-size: 30px;
    margin-top: 10px;
}

.NowPlaying h3{
    color: #FFFFFF;
    font-size: 15px;    
}

.NowPlaying img{
    float: left;
    margin-right: 20px;
    height: 100px;
}

.MovieItem {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 5px;
    margin: 10px;
    text-align: center;
    box-shadow: 0 0 6px #ccc;
    cursor: pointer;
    transition: all 0.3s ease;
}

.MovieItem img {
    width: 230px;
    height: 340px;

}

.MovieItem:hover {
    transform: rotate(1deg) scale(1.03);
    opacity:0.9;
}

.MovieItem div {
    margin-top: 10px;
}

.Movies {
    margin: auto;
}

.search {
    margin-top: 10px;
}

@media only screen and (max-width: 600px) {
    .search {
        padding: 0;
        margin: 0;
    }
  
  }

.searchInput {
    height: 120px;
}

.searchInput img{
    height: 100%;
}

